import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card } from 'semantic-ui-react';
import { Carousel } from 'react-responsive-carousel';
import TechnologyIcons from '../components/technology-icons';
import Layout from '../layouts';
import get from 'lodash/get';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './project-page.css';


export default class ProjectPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: get(this.props, 'data.contentfulProject'),
    };
  }
  render() {
    const { cocreators, description, url, repo, icon, screenshots, technologies, year, attachments } = this.state.project;

    return (
      <Layout title={this.state.project.title}>
        <div>
          <div className="page">
            <Card className="page-container" fluid>
              <Carousel className="page-carousel" showThumbs={false}>
                <GatsbyImage style={{ width: "100%", height: 450 }} alt={`${this.state.project.title} Icon`} image={getImage(icon)} />
                {screenshots && screenshots.map(screenshot => <GatsbyImage style={{ width: "100%", height: 450 }} key={screenshot.id} alt={screenshot.description} image={getImage(screenshot)} />)}
              </Carousel>
              <section className="page-inner">
                <Card.Meta>
                  <span className="details">
                    <p>{year}</p>
                    <p>{cocreators ? cocreators.join(", ") : null}</p>
                  </span>
                  <div className="links">
                  {url ? <a href={url}>{url}</a> : null}
                  {repo ? <a href={repo}>{repo}</a> : null}
                  </div>
                </Card.Meta>
                <Card.Description>
                  <div className="content" dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }} />
                  {attachments && attachments.length > 0 && <>
                    <br />
                    <h3>Related Files</h3>
                    {attachments.map(attachment => <a key={attachment.id} href={attachment.file.url}>{attachment.title}</a>)}
                  </>}
                </Card.Description>
                <Card.Content extra>
                  <span>
                    <TechnologyIcons technologies={technologies} defaultClassName="fa-2x" />
                  </span>
                </Card.Content>
              </section>
            </Card>
          </div>
        </div>
      </Layout>
    );
  }
}

export const templateQuery = graphql`
  query ProjectById($id: String!) {
    contentfulProject(id: { eq: $id }) {
      title
      year
      # cocreators
      description {
        childMarkdownRemark {
          html
        }
      }
      technologies
      url
      repo
      icon {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      screenshots {
        id
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
      attachments {
        title
        id
        file {
          url
        }
      }
    }
  }
`;
